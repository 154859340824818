.dashboard-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-header h1 {
  margin: 0;
  font-size: 2.5em;
  color: #333;
}

.dashboard-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.dashboard-stat {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 200px;
  max-width: 200px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.dashboard-stat p {
  margin: 0;
  font-size: 1.2em;
  color: #666;
}

.dashboard-stat span {
  display: block;
  margin-top: 10px;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.card-body h2{
  font-weight:bold;
}