/* src/components/NavBar.css */
.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: rgb(21, 90, 159);
    color: white;
    position: relative;
  }
  
  .logo {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 25px;
    cursor: pointer;
    z-index: 2;
  }
  
  .menu-icon div {
    width: 100%;
    height: 4px;
    background-color: white;
    transition: 0.4s;
  }
  
  .menu-icon.open .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .logo-img{
    vertical-align: middle;
    width: 60px !important;
    height: auto;
  }
  .menu-icon.open .bar2 {
    opacity: 0;
  }
  
  .menu-icon.open .bar3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  .nav-items {
    position: fixed;
    top: 0;
    right: -100%; /* Move completely off-screen */
    width: 50%; /* Cover half the screen */
    height: 100%;
    background-color: rgb(21, 90, 159);
    padding-top: 60px;
    transition: right 0.4s ease-out;
    z-index: 1;
  }
  
  .nav-items ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .nav-item {
    padding: 10px;
    text-align: left;
    list-style: none;
  }
  
  .nav-item a {
    color: white;
    text-decoration: none;
  }
  
  .nav-items.open {
    right: 0; /* Slide in from the right */
  }
  
  @media(min-width: 600px) {
    .menu-icon {
      display: none;
    }
  
    .nav-items {
      position: static;
      width: auto;
      height: auto;
      display: flex;
      padding-top: 0;
      background-color: transparent;
    }
  
    .nav-item {
      padding: 0 10px;
    }
  }
  