.bi{
   padding-right: 10px;
   padding-top: 50px; 
}

h1,h2,h3,h4,h5,h6{
    font-weight: bolder;
}

.text-bold{
    font-weight:bolder;
}